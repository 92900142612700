import React, { Component } from "react"
import { Link } from "gatsby"
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import SimpleModal from "../components/utilities/SimpleModal"

import "./casestudyfleetadvantage.scss"

import imgQuote from "../images/quotation_mark.webp"

export default ({ pageContext }) => (
  <Layout>
    <SEO
      title={pageContext.yoast.title}
      description={pageContext.yoast.metadesc}
      ogimg={pageContext.yoast.opengraph_image}
      link={pageContext.link}
    />
    <div
      className="case-study-fleetadvantage-wrapper"
      style={{ overflowX: "hidden" }}
    >
      <div className="container-fluid">
        <div className="row">
          <div
            className="header-container"
            style={{
              backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
            }}
          >
            <img src={imgQuote} alt="" />
            <h2>{pageContext.acf.header_text}</h2>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container detail-wrapper">
          <div className="row">
            <div className="col-md-8">
              <img
                src={
                  pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                    .fluid.src
                }
                className="client-logo"
                width={pageContext.acf.client_logo.media_details.width / 2.5}
                height={pageContext.acf.client_logo.media_details.height / 2.5}
                alt={pageContext.yoast.opengraph_title}
              />
            </div>
            <div className="col-md-4">
              
            </div>
            <div className="col-12 margin-b">
              <div className="bar"></div>
            </div>
            <div className="col-12 margin-b">
              <p>
                Fleet Advantage—a Merit Mile client for over ten years—is the
                largest independent lessor for heavy-duty Class-8 trucks and has
                over $3 Billion assets and over 20,000 units under its Life Cycle
                Cost Management program. This comprises a client portfolio of more
                than 50 of America’s top corporate fleets, including five (5) of
                the top 10 private fleets in the country. Fleet Advantage
                guarantees the absolute lowest cost of operation by providing
                fleet asset management, financing solutions, and fleet analytics,
                using the latest equipment technology to achieve optimum vehicle
                productivity and maximum safety. Fleet Advantage's model of TCO,
                clean diesel, and safety-enhanced trucks with shorter life cycles
                complement their customers’ ESG goals.
              </p>
            </div>
            <div className="col-12 margin-b">
              <img
                src="https://apiold.meritmile.com/wp-content/uploads/2024/09/fleet-of-trucks.webp"
                className="img-fluid"
                alt="Fleet of Trucks"
                style={{ scale: "115%", marginTop: "40px", marginBottom: "40px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container detail-wrapper">
          <div className="row">
            <div className="col-12">
              <h3>Challenge</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 margin-b">
              <p>
                Fleet Advantage relied on Merit Mile to help communicate its
                innovative approaches, share success stories from satisfied
                clients, and establish itself as the leading provider of fleet
                management solutions.
              </p>
              <p>
                Managing a fleet of heavy-duty trucks involves numerous
                complexities, from cost management to ensuring the latest safety
                features. Fleet Advantage faces a unique PR challenge in
                communicating these
              </p>
            </div>
            <div className="col-md-6 margin-b">
              <p>
                multifaceted benefits and effectively conveying the value and
                practical benefits of these solutions to fleet managers and
                decision-makers.
              </p>
              <p>
                The goal was to educate potential clients on the advantages of
                their offerings and showcase how their expertise can transform
                fleet operations, making them more efficient, cost-effective,
                and safe.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container detail-wrapper">
        <div className="row">
          <div className="col-md-6 margin-b">
            <img
              src="https://apiold.meritmile.com/wp-content/uploads/2024/09/fa-solutions.webp"
              className="img-fluid"
              alt="Fleet Advantage Solutions"
              width="100%"
              style={{
                scale: "105%",
                marginBottom: "40px",
                marginLeft: "-30px",
              }}
            />
          </div>
          <div className="col-md-6 margin-b">
            <div
              className="third-paragraph"
              dangerouslySetInnerHTML={{
                __html: pageContext.acf.third_paragraph,
              }}
              style={{
                paddingTop: "2em",
                paddingBottom: "2em",
                marginBottom: "1em",
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              Merit Mile created unique story angles that highlight Fleet
              Advantage's success in optimizing fleet operations, reducing
              costs, and enhancing safety through advanced technologies,
              while also securing interviews with key leadership and media
              placements in top-tier industry publications, showcasing Fleet
              Advantage as a leading authority in fleet management. Positioning
              Fleet Advantage executives as industry thought leaders was a
              priority, achieved by facilitating contributions to influential
              trade publications. This ultimately reinforced Fleet Advantage's
              key messages and highlighted its category leadership in fleet
              modernization, cost management strategies, and the transition to
              alternative fueled vehicles.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <img
              src="https://apiold.meritmile.com/wp-content/uploads/2024/09/fleet-operations-in-2024.webp"
              className="img-fluid"
              alt="Fleet Operations in 2024"
            />
          </div>
        </div>
      </div>
      <div className="fullWidthGreenCTA">
        <div className="container">
          <div className="row margin-b">
            <div className="col-12">
              <p>
                Fleet Advantage's Kids Around the Corner Foundation hosted a
                Back-to-School Backpack Drive on July 17th in partnership with
                Henderson Behavioral Health, a nonprofit organization providing
                behavioral health services to the South Florida
                community.
              </p>
              <p>
                Merit Mile secured local coverage of the Backpack
                Drive with CBS Miami, including an interview with Fleet
                Advantage CEO Brian Holland on what it means to give back to
                their local community.
              </p>
            </div>
          </div>
          <div className="row margin-b">
            <div className="col-md-8">
              <img
                src="https://apiold.meritmile.com/wp-content/uploads/2024/09/fleet-henderson@2x.webp"
                className="img-fluid"
                alt="Fleet Advantage - Henderson Behavioral"
              />
            </div>
            <div className="col-md-4">
              <img
                src="https://apiold.meritmile.com/wp-content/uploads/2024/09/fleet-kids-around-the-corner@2x.webp"
                className="img-fluid"
                alt="Fleet - Kids Around The Corner"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#D9D9D959', paddingTop: '60px'}}>
        <div className="container detail-wrapper">
          <div className="row">
            <div className="col-12">
              <h3>Results</h3>
            </div>
          </div>
          <div className="row margin-b">
            <div className="col-md-6">
              <p>
                With the help of Merit Mile, Fleet Advantage has significantly increased its brand presence, becoming a regular feature in key industry publications and leading business media outlets in the United States, including transportation, fleet management, and logistics. The company's executives have established themselves as thought leaders, frequently providing expert insights and commentary on critical topics, and the company's innovative solutions and success stories are now widely recognized and respected within the industry.
              </p>
            </div>
            <div className="col-md-6">
              <p>
              Fleet Advantage's contributions to the transportation fleet management industry have also been acknowledged through hundreds of industry awards, further cementing its position as a leader in the field. The successful execution of the integrated PR strategy has effectively communicated the company's value propositions, resulting in a more prominent and influential presence within the industry's media landscape.
              </p>
            </div>
          </div>
          <div className="row margin-b">
            <div className="col-12 text-center">
              <img
                src="https://apiold.meritmile.com/wp-content/uploads/2024/09/fleet-logos@2x.png"
                className="img-fluid margin-b"
                alt="Fleet Logos"
              />
            </div>
          </div>
          <div className="row margin-b">
            <div className="col-md-6">
              <h4 className="blueTitle">
                <b>3,356 Total clips</b>
                <br />
                Total brand mentions: <b>3,356</b>
                <br />
                Total impressions: <b>4,916,636,380</b>
                <br />
                Total ad equivalency: <b>$6,969,239</b>
                <br />
                Total ROI: <b>Monthly Spend 11.46X</b>
              </h4>
            </div>
            <div className="col-md-6">
              <img
                src="https://apiold.meritmile.com/wp-content/uploads/2024/09/fleet-results@2x.png"
                className="results-image img-fluid margin-b"
                alt="Fleet Results"
              />
            </div>
          </div>
        </div>
      </div>  
      <div className="fullWidthGrayCTA">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <h5>Learn more about how Merit Mile can support your ABM, demand generation, and content marketing goals by contacting <a href="mailto:Hello@MeritMile.com?subject=Fleet%20Advantage%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>hello@meritmile.com.</b></a></h5>
                  </div>
              </div>
          </div>
      </div>
      <div className="yourteam-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Your Merit Mile Team</h3>
            </div>
          </div>
          <div className="row margin-b">
            <div className="col-12">
              <div className="team-cards">
                <div className="team-card">
                  <img
                    src="https://www.meritmile.com/uploads/2022/08/js-thumb.jpg"
                    alt="John Sternal"
                    className="img-fluid"
                  />
                  <h4>John Sternal</h4>
                  <p>
                    Partner, PR,
                    <br />
                    Social, Research
                    <br />
                    &nbsp;
                  </p>
                </div>
                <div className="team-card">
                  <img
                    src="https://www.meritmile.com/uploads/2022/08/jb-thumb.jpg"
                    alt="Justin Baronoff"
                    className="img-fluid"
                  />
                  <h4>Justin Baronoff</h4>
                  <p>
                    Public Relations
                    <br />
                    Account Executive
                    <br />
                    &nbsp;
                  </p>
                </div>
                <div className="team-card">
                  <img
                    src="https://apiold.meritmile.com/wp-content/uploads/2024/09/ts-thumb.jpg"
                    alt="Taryn Smith"
                    className="img-fluid"
                  />
                  <h4>Taryn Smith</h4>
                  <p>
                    Public Relations
                    <br />
                    Account Executive
                    <br />
                    
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fullWidthImageWrapper">
        <img
          src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
          alt="Merit Mile Icon Divider"
          className="img-fluid"
        />
      </div>
      <div className="about-wrapper">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>About Merit Mile</h2>
            </div>
          </div>
          <div className="row margin-b">
            <div className="col-md-6">
              <p>
                Established in 2007 in Boca Raton, FL, Merit Mile is a marketing
                services provider focused on helping companies and corporate
                marketing departments build brands, drive sales revenue, and
                garner publicity in their respective industries. Our unique
                differentiator at Merit Mile is our ability to 100% own the
                message, the publicity, and the demand generation aspects of the
                corporate marketing function. Clients hire us and rehire us for
                our ability to refine their messaging/value proposition, develop
                top and mid-funnel leads, and get the type of editorial
                publicity that differentiates them from their competitors. From
                branding to PR to demand generation, Merit Mile is the
                high-octane fuel that invigorates corporate growth engines. To
                learn more, please email us at Hello@MeritMile.com, call us at
                1-561-362-888, or visit us at www.MeritMile.com.{" "}
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg"
                alt="Merit Mile Headquarter in Boca Raton, FL"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container quote-wrapper">
        <div className="row">
          <div className="col-12">
            <p>
              For more insights and to connect
              <br />
              with Merit Mile{" "}
              <a href="mailto:Hello@MeritMile.com?subject=Fleet%20Advantage%20Case%20Study%20Inquiry">
                <b style={{ color: "black" }}>email us here</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
